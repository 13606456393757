import {
  btnAccount,
  btnNip,
  errorEmail,
  inputAccount,
  inputEmail,
  btnSendFile,
  inputNip,
  labelInput,
  weightAccount,
  btnUpload,
  contactEmailInput,
  contactEmailSendBtn, contactErrorMail
} from "./const";
import { Helper } from "./helper";

const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  export class Validators {



  validatorNip() {
    const nipErrorElement = document.getElementById("error-nip");

    inputNip && inputNip.addEventListener("keyup", function() {
      let nip = Helper.sanitizeString(inputNip.value);

      let sum = 0;
      let controlNumber = parseInt(nip.substring(9, 10));
      for (let i = 0; i < weight.length; i++) {
        sum += parseInt(nip.substring(i, i + 1)) * weight[i];
      }

      Validators._displayError(
        nip.length === 10,
        sum % 11 === controlNumber,
        nipErrorElement,
        nip.length === 0
      );
      Validators._disableBtn(
        nip.length === 10,
        sum % 11 === controlNumber,
        btnNip,
        nip.length === 0
      );
    });
  }

  validatorAccount() {
    const errorNumberAccountElement = document.getElementById("error-account");
    inputAccount && inputAccount.addEventListener("keyup", function() {
      let number = Helper.sanitizeString(inputAccount.value);

      number = number + "2521";
      number = number.substr(2) + number.substr(0, 2);
      let Z = 0;
      let i;
      for (i = 0; i < 30; i++) {
        Z += number[29 - i] * weightAccount[i];
      }
      Validators._displayError(
        number.length === 30,
        Z % 97 === 1,
        errorNumberAccountElement,
        number.length === 4
      );
      Validators._disableBtn(number.length === 30,Z % 97 === 1, btnAccount, number.length === 4);
    });
  }

  validatorEmail() {
    const re = /\S+@\S+\.\S+/;
    inputEmail &&  inputEmail.addEventListener("keyup", function() {
      labelInput.classList.add("disabled-input");
      btnSendFile.disabled = true;
      btnUpload.disabled = true;

      Validators._displayError(
        inputEmail.value.length > 1,
        re.test(inputEmail.value),
        errorEmail,
        inputEmail.value.length === 0
      );
    });

    let value =  inputEmail
      ? inputEmail.value
      : '';
    return re.test(value);
  }


  validatorContactEmail(){
    const re = /\S+@\S+\.\S+/;
    contactEmailInput && contactEmailInput.addEventListener("keyup", function() {
      contactEmailSendBtn.disabled = true;

      Validators._displayError(
        contactEmailInput.value.length > 1,
        re.test(contactEmailInput.value),
        contactErrorMail,
        contactEmailInput.value.length === 0
      );
    });

     let value =  contactEmailInput
     ? contactEmailInput.value
     : '';
    return re.test(value);
  }




  static _displayError(specifyLength, result, element, lengthResult, className = 'is-hidden') {
    specifyLength
      ? result
        ? element.classList.add(className)
        : lengthResult
        ? element.classList.add(className)
        : element.classList.remove(className)
      : element.classList.add(className);
  }

  static _disableBtn(specifyLength, result, element, lengthResult) {
    specifyLength
      ? result
        ? (element.disabled = false)
        : lengthResult
        ? (element.disabled = false)
        : (element.disabled = true)
      : (element.disabled = true);
  }

  static runValidatorCheckNumberAccount (number){
    number = number + "2521";
    number = number.substr(2) + number.substr(0, 2);
    let Z = 0;
    let i;
    for (i = 0; i < 30; i++) {
      Z += number[29 - i] * weightAccount[i];
    }
    return Z % 97 === 1;
  }

  static runValidatorNip(nip){

    let sum = 0;
    let controlNumber = parseInt(nip.substring(9, 10));
    for (let i = 0; i < weight.length; i++) {
      sum += parseInt(nip.substring(i, i + 1)) * weight[i];
    }

    return sum % 11 === controlNumber
  }
}
