import {
  capchaInput,
  contactEmailInput,
  contactEmailSendBtn,
  contactMsgInput, contactTelInput,
  errorSendEmail
} from "../classes/const";
import axios from "axios";
import { ApiError } from "../classes/api-errro-msg";
import {Helper} from "../classes/helper";
import {API_URL} from "../../config/config";
import {Modal} from "../classes/modal";
import {Validators} from "../classes/validators";
import {Loader} from "../classes/loader";

export class SendMsgService {


  valid(){

    contactEmailInput && contactEmailInput.addEventListener("keyup", function(){

      contactEmailInput.value && contactMsgInput.value
        ? contactEmailSendBtn.disabled =false
        : contactEmailSendBtn.disabled = true
    });

    contactMsgInput && contactMsgInput.addEventListener("keyup", function(){

      contactMsgInput.value && contactEmailInput.value
        ? contactEmailSendBtn.disabled =false
        : contactEmailSendBtn.disabled = true
    });
  }


  send() {

    contactEmailSendBtn && contactEmailSendBtn.addEventListener("click", function(){

      const data = {
        email: contactEmailInput.value,
        msg: contactMsgInput.value,
        tel: contactTelInput.value,
        capcha: capchaInput.value};

      const showLoaderAndSenMSG = () => {
        Loader.show();
        axios
          .post(`${API_URL}/send-msg.php`, Helper.encodeForm(data), {
            headers: {
              Accept: "application/json"
            }
          })
          .catch(function(error) {
            Helper.refreshCapcha();
            ApiError.checkError(error, errorSendEmail);
          })
          .then((response) => {
            if (response.data.code === 200) {
              Modal.contactModal(true);
            }
          }).finally(() => {
          Helper.refreshCapcha();
          Loader.hide()
        })
      };

      const valid = new Validators();
      valid.validatorContactEmail()
      ? showLoaderAndSenMSG()
        : null
    });
  }
}
