import axios from "axios";
import {
  errorEmailServer, inputEmail, labelInput, btnUpload, capchaInput
} from "../classes/const";
import { Validators } from "../classes/validators";
import { Clear } from "../classes/clear";
import { ApiError } from "../classes/api-errro-msg";
import { Helper } from "../classes/helper";
import {Loader} from "../classes/loader";
import {API_URL} from "../../config/config";

export class SaveEmailService {
  saveEmail() {
    inputEmail && inputEmail.addEventListener("change", () => {
      Clear.clearErrorEmailServer();
      let formData = { mail: inputEmail.value, capcha: capchaInput.value };


      const valid = new Validators();
      valid.validatorEmail()
        ? axios
            .post(`${API_URL}/save-email.php`, Helper.encodeForm(formData), {
              headers: {
                Accept: "application/json"
              }
            })
            .catch(function(error) {
              Helper.refreshCapcha();
              ApiError.checkError(error, errorEmailServer);
            })
            .then(response => {
              Loader.show();
              const showButtonAnd = () => {
                Helper.refreshCapcha();
                labelInput.classList.remove("disabled-input");
                btnUpload.disabled = false;
                inputEmail.disabled = true;
              };

              response && response.data.code === 201 ? showButtonAnd() : null;
            }).finally(() => Loader.hide())
        : null;
    });
  }
}
