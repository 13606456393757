import {
  btnUpload,
  outputNumberAccount,
  resultUpload,
} from "../classes/const";
import { Modal } from "../classes/modal";
import {CsvParse} from "../classes/csv-parse";


export class UploadTextFile {
  uploadFunction() {
    btnUpload ?
    window.File && window.FileList && window.FileReader
      ? btnUpload.addEventListener("change", function(event) {
          let files = event.target.files; //FileList object
          const file = files[0];
          let validateFile;
          const csvParse = new CsvParse(file);

        outputNumberAccount.innerHTML = "";

          switch (file.type) {
            case "text/plain":
              csvParse.start();
              validateFile = true;
              break;
            case "text/csv":
              csvParse.start();
              validateFile = true;
              break;
            default:
              validateFile = false;
              break;
          }

          validateFile
            ? resultUpload.classList.remove("is-hidden")
            : Modal.uploadFileError(true);
        })
      : alert("Twoja przeglądarka nie wspiera tej obsługi")
      : null;
  }
}
