import Inputmask from "inputmask";
let currentOption = 'option-check-nip';
import {contactTelInput, inputEmail, resultAccount, resultNip, resultUpload} from './const'
import {Clear} from "./clear";

export class SwitchBox {

  toogle() {
    let elementsArray = document.querySelectorAll(".box-option");


    elementsArray.forEach(function (elem) {
      elem.addEventListener("click", function () {
        resultNip.classList.add('is-hidden');
        resultAccount.classList.add('is-hidden');
        resultUpload.classList.add('is-hidden');
        inputEmail.value ='';
        inputEmail.disabled = false;
        Clear.clearResultFromTable();


        const currentOptionElement = document.getElementById(`${currentOption}`);
        const contentCurrentOptionElement = document.getElementById(`content-${currentOption}`);


        currentOptionElement.classList.add('has-background-link');
        currentOptionElement.classList.remove('custom-green-bg');
        contentCurrentOptionElement.classList.add('is-hidden');

        const selectedElement = document.getElementById(`${elem.id}`);
        const contentSelectedElement = document.getElementById(`content-${elem.id}`);

        selectedElement.classList.remove('has-background-link');
        selectedElement.classList.add('custom-green-bg');
        contentSelectedElement.classList.remove('is-hidden');

        currentOption = elem.id;
      });
    });
  }

  setInputMask() {
    const nip = document.getElementById('input-nip');
    const numberAccount = document.getElementById('input-number-account');

    nip
      ? Inputmask({mask:"999-999-99-99"}).mask(nip) : null;
    numberAccount
      ? Inputmask({mask:"99-9999-9999-9999-9999-9999-9999"}).mask(numberAccount) : null;
    contactTelInput
      ?  Inputmask({mask:"999-999-999"}).mask(contactTelInput) : null;

  }

}
