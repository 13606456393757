import {capchaInput} from "./const";

export class Helper {
  static getDateYearMonthDay() {
    let date = new Date();
    return date.toISOString().substring(0, 10);
  }

  static sanitizeString(string) {
    return string.replace(/[\\/"_\n\r\t\s-]/gi, "");
  }

  static clearWhiteSpace(string){
    return string.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
  }

  static encodeForm(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  static refreshCapcha(){
    grecaptcha.ready(function() {
      grecaptcha.execute('6LcBH74UAAAAALMyVjd-p-97NXtdnT5Xt2u0LKqK',
        {action: 'homepage'}).then(function(token) {

          capchaInput.value  = token;
      });
    });
  }
}
