import {
  btnSendFile,
  btnUpload,
  labelInput,
  tableTbodyResult,
  tableTheadResult
} from "./const";
import {Validators} from "./validators";

export let listNipAndAccount = [];

export class CsvParse {
  constructor(file) {
    this.file = file;
  }

  start() {
    const reader = new FileReader();

    reader.addEventListener("load", event => {
      let textFile = event.target;
      let segments;
      let maxColumn = 0;
      const result = textFile.result;

      if (
        result.indexOf(",") !== -1 ||
        result.indexOf(";") !== -1 ||
        result.indexOf("|") !== -1
      ) {
        let newLine = result.split("\n");

        newLine.forEach(nextLine => {
          const tr = document.createElement("tr");
          tableTbodyResult.appendChild(tr);
          segments = nextLine.split(/[;,|]/);
          maxColumn = segments.length > maxColumn ? segments.length : maxColumn;
          segments &&
          segments.forEach((word, ) => {
            if (
              Validators.runValidatorCheckNumberAccount(segments[0]) &&
              Validators.runValidatorNip(segments[1])
            ) {
              let find = listNipAndAccount.find(
                item =>
                  item.nip === segments[1] &&
                  item.number_account === segments[0]
              );

              !find
                ? listNipAndAccount.push({
                  nip: segments[1],
                  number_account: segments[0]
                })
                : null;
              find = null;
            }
            const td = document.createElement("td");
            td.innerHTML = word;
            tr.appendChild(td);
          });
        });


        const trInThead = document.createElement("tr");
        tableTheadResult.appendChild(trInThead);

        for (let i = 0; i < maxColumn; i++) {
          const th = document.createElement("th");

          th.innerHTML = `Kolumna ${i + 1}`;
          th.id = `select-${i}`;

          trInThead.appendChild(th);
        }
        btnSendFile.disabled = false;
      } else {
        alert(
          "Plik nie został obsłużony poprawnie  odśwież stronę, i spróbuj ponownie"
        );
      }

      labelInput.classList.add("disabled-input");
      btnUpload.disabled = true;
    });
    reader.readAsText(this.file);
  }
}
