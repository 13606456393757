import {errorEmailServer, tableTbodyResult, tableTheadResult} from "./const";


export class Clear {

  static clearErrorEmailServer(){
    errorEmailServer.innerText ='';
  }


  static clearResultFromTable(){
    tableTheadResult.innerHTML ="";
    tableTbodyResult.innerHTML ="";

  }

}
