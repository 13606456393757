import axios from "axios";
import { Helper } from "../classes/helper";
import {
  btnAccount,
  contentAccount,
  inputAccount,
  listResultAccount,
  resultAccount
} from "../classes/const";

import { DisplayResponse } from "../classes/display-response";
import {ApiError} from "../classes/api-errro-msg";
import {Loader} from "../classes/loader";
import {API_URL_GOV} from "../../config/config";

export class AccountService {
  run() {
    btnAccount && btnAccount.addEventListener("click", () => {
      let account = Helper.sanitizeString(inputAccount.value);
      listResultAccount.innerHTML = "";
      Loader.show();
      axios
        .get(
          `${API_URL_GOV}/bank-account/${account}/?date=${Helper.getDateYearMonthDay()}`,
          {
            headers: {
              Accept: "application/json"
            }
          }
        )
        .catch((error) => {
          ApiError.checkError(error, listResultAccount);
        })
        .then(response => {
          if (response.data.result.subjects[0]) {
            const {
              statusVat,
              nip,
              workingAddress,
              krs,
              residenceAddress,
              registrationLegalDate,
              regon,
              pesel,
              name,
              hasVirtualAccounts,
              accountNumbers
            } = response.data.result.subjects[0];
            statusVat
              ? DisplayResponse.addStatusVat(
                  "Status VAT",
                  statusVat,
                  listResultAccount
                )
              : "";
            name
              ? DisplayResponse.addToList("Nazwa", name, listResultAccount)
              : null;
            nip
              ? DisplayResponse.addToList("Nip", nip, listResultAccount)
              : null;

            residenceAddress
              ? DisplayResponse.addToList(
              "Adres", residenceAddress, listResultAccount)
              : null;
            workingAddress
              ? DisplayResponse.addToList(
                  "Adres pracy",
                  workingAddress,
                  listResultAccount
                )
              : null;
            krs
              ? DisplayResponse.addToList("Krs", krs, listResultAccount)
              : null;
            registrationLegalDate
              ? DisplayResponse.addToList(
                  "Data rejestracji",
                  registrationLegalDate,
                  listResultAccount
                )
              : null;
            regon
              ? DisplayResponse.addToList("Regon", regon, listResultAccount)
              : null;
            pesel
              ? DisplayResponse.addToList("Pesel", pesel, listResultAccount)
              : null;


            DisplayResponse.addToList(
                  "Podmiot posiada konta wirtualne",
                  hasVirtualAccounts === true ? "Tak" : "Nie",
                  listResultAccount
                );

            accountNumbers
              ? DisplayResponse.addAccount(accountNumbers, listResultAccount)
              : null;
          } else {
            DisplayResponse.nullResult(listResultAccount, "konta");
          }
        }).finally(() => Loader.hide());

      contentAccount.classList.add("is-hidden");
      resultAccount.classList.remove("is-hidden");
    });
  }
}
