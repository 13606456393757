import '../sass/main.scss'
import {SwitchBox} from "./classes/switch-box";
import {Validators} from "./classes/validators";
import {NipService} from "./services/nip-service";
import {AccountService} from "./services/account-service";
import {SaveEmailService} from "./services/save-email-service";
import {UploadTextFile} from "./services/upload-text-file";
import {SendEmailsService} from "./services/send-emails-service";
import {Modal} from "./classes/modal";
import {SendMsgService} from "./services/send-msg-service";


const switchBox = new SwitchBox();
const validator = new Validators();
const api = new NipService();
const apiAccount = new AccountService();
const apiEmail = new SaveEmailService();
const uploadTextFile = new UploadTextFile();
const emails = new SendEmailsService();
const modal = new Modal();
const sendMsg = new SendMsgService();
modal.emailListener();
modal.uploadFileErrorListener();
modal.contactModalSuccessListener();
switchBox.toogle();
switchBox.setInputMask();

validator.validatorNip();
validator.validatorAccount();
validator.validatorEmail();
validator.validatorContactEmail();
api.run();
apiAccount.run();
apiEmail.saveEmail();
uploadTextFile.uploadFunction();
emails.send();
sendMsg.send();
sendMsg.valid();


