export class DisplayResponse {
  static addToList(label, value, element, isError = false) {
    const li = document.createElement("li");
    isError ? li.classList.add('has-text-danger') : null;
    li.innerHTML = `${label}: <span class="has-text-weight-bold">${value}</span>`;

    element.appendChild(li);
  }

  static addStatusVat(label, value, element) {
    const li = document.createElement("li");
    li.innerHTML =
      value === "Czynny"
        ? `${label}:  <span class="tag custom-green-bg has-text-white" >${value}</span>`
        : `${label}:  <span class="tag has-background-danger has-text-white" >${value}</span>`;

    element.appendChild(li);
  }

  static addAccount(accounts, element) {
    const liMain = document.createElement("li");
    const olAccount = document.createElement("ol");

    liMain.innerHTML = `Konta bankowe:`;
    liMain.appendChild(olAccount);
    element.appendChild(liMain);

    const li = document.createElement("li");
    li.classList.add("li-account");

    accounts.forEach(account => {
      const span = document.createElement("li");
      span.innerHTML = DisplayResponse.setMaskAccountNumber(account) + "<br>";

      olAccount.appendChild(span);
    });

    element.appendChild(olAccount);
  }

  static nullResult(element, numName) {

    const li = document.createElement("li");
    li.innerHTML = `<span class="has-text-weight-bold">Brak wyników dla podanego numeru ${numName}</span>`;

    element.appendChild(li);
  }

  static setMaskAccountNumber(account) {
    let string = "";

    for (let i = 0; i < account.length; i++) {
      string +=
        i === 1 || i === 5 || i === 9 || i === 13 || i === 17 || i === 21
          ? account.charAt(i) + " "
          : account.charAt(i);
    }

    return string;
  }
}
