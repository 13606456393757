import { DisplayResponse } from "./display-response";

// const errorList = [
//   { errorCode:"WL-100", errorMsg: "Wystąpił nieoczekiwany błąd serwera."},
//   { errorCode:"WL-101", errorMsg: "Pole 'data' nie może być puste."},
//   { errorCode:"WL-102", errorMsg: "Pole 'data' ma nieprawidłowy format."},
//   { errorCode:"WL-103", errorMsg: "Data nie może być datą przyszłą."},
//   { errorCode:"WL-104", errorMsg: "Pole 'REGON' nie może być puste."},
//   { errorCode:"WL-105", errorMsg: "Pole 'REGON' ma nieprawidłową długość. Wymagane 9 lub 14 znaków."},
//   { errorCode:"WL-106", errorMsg: "Pole 'REGON' zawiera niedozwolone znaki. Wymagane tylko cyfry."},
//   { errorCode:"WL-107", errorMsg: "Nieprawidłowy REGON."},
//   { errorCode:"WL-108", errorMsg: "Pole 'numer konta' nie może być puste."},
//   { errorCode:"WL-109", errorMsg: "Pole 'numer konta' ma nieprawidłową długość. Wymagane 26 znaków."},
//   { errorCode:"WL-110", errorMsg: "Pole 'numer konta' zawiera niedozwolone znaki. Wymagane tylko cyfry."},
//   { errorCode:"WL-111", errorMsg: "Nieprawidłowy numer konta bankowego."},
//   { errorCode:"WL-112", errorMsg: "Pole 'NIP' nie może być puste."},
//   { errorCode:"WL-113", errorMsg: "Pole 'NIP' ma nieprawidłową długość. Wymagane 10 znaków."},
//   { errorCode:"WL-114", errorMsg: "Pole 'NIP' zawiera niedozwolone znaki. Wymagane tylko cyfry."},
//   { errorCode:"WL-115", errorMsg: "Nieprawidłowy NIP."},
//   { errorCode:"WL-116", errorMsg: "Pole 'nazwa pomiotu' nie może być puste."},
//   { errorCode:"WL-117", errorMsg: "Pole 'nazwa pomiotu' za krótkie. Wymagane przynajmniej 5 znaków."},
//   { errorCode:"WL-118", errorMsg: "Data sprzed zakresu rejestru."},
//   { errorCode:"WL-190", errorMsg: "Niepoprawne żądanie."},
//   { errorCode:"WL-195", errorMsg: "Zaktualizowaliśmy bazę danych. Wykonaj ponownie zapytanie, aby otrzymać aktualne dane."},
//   { errorCode:"WL-196", errorMsg: "Trwa aktualizacja bazy danych. Spróbuj ponownie później."},
// ];
export const errorTextUnexpected =
  "Wystąpił nieoczekiwany błąd serwera. Spróbuj ponownie za chwilę.";

export class ApiError {
  static checkError(error, addToComponent) {
    if (error.response) {
      Array.isArray(error.response.data)
        ? error.response.data.forEach(err =>
            ApiError._addToList(err.message, addToComponent)
          )
        : ApiError._addToList(error.response.data.message, addToComponent);
    } else if (error.request) {
      ApiError._addToList(errorTextUnexpected, addToComponent);
    } else {
      ApiError._addToList(errorTextUnexpected, addToComponent);
    }
  }
  static _addToList(errorMsg, componentHtml) {
    DisplayResponse.addToList("", errorMsg, componentHtml, true);
  }
}
