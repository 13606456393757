import {
   btnNip, contentNip, inputNip, listResultNip, resultIconNip, resultNip
} from "../classes/const";
import { Helper } from "../classes/helper";
import axios from "axios";

import { DisplayResponse } from "../classes/display-response";
import { ApiError } from "../classes/api-errro-msg";
import {Loader} from "../classes/loader";
import {API_URL_GOV} from "../../config/config";

export class NipService {
  run() {
    btnNip && btnNip.addEventListener("click", () => {
      let nip = Helper.sanitizeString(inputNip.value);
      listResultNip.innerHTML = "";
      Loader.show();
      axios
        .get(`${API_URL_GOV}/nip/${nip}/?date=${Helper.getDateYearMonthDay()}`, {
          headers: {
            Accept: "application/json"
          }
        })
        .catch(error => {
          resultIconNip.classList.add('has-text-danger');
          ApiError.checkError(error, listResultNip);
        })
        .then(response => {
          if (response && response.data && response.data.result) {
            DisplayResponse.addToList("Numer zapytania", response.data.result.requestId, listResultNip);
            if (response.data.result.subject) {
              resultIconNip.classList.remove('has-text-danger');
              resultIconNip.classList.add('has-text-success');
              DisplayResponse.addToList("Status", `<span class="tag custom-green-bg has-text-white">Znaleziono</span>`, listResultNip);
              const {
                statusVat,
                nip,
                name,
                workingAddress,
                krs,
                registrationLegalDate,
                residenceAddress,
                regon,
                pesel,
                hasVirtualAccounts,
                accountNumbers
              } = response.data.result.subject;

              name
                ? DisplayResponse.addToList("Nazwa", name, listResultNip)
                : null;
              nip ? DisplayResponse.addToList("Nip", nip, listResultNip) : null;
              workingAddress
                ? DisplayResponse.addToList(
                    "Adres",
                    workingAddress,
                    listResultNip
                  )
                : null;
              krs ? DisplayResponse.addToList("Krs", krs, listResultNip) : null;

              residenceAddress
                ? DisplayResponse.addToList(
                  "Adres", residenceAddress, listResultNip)
                : null;
              registrationLegalDate
                ? DisplayResponse.addToList(
                    "Data rejestracji",
                    registrationLegalDate,
                    listResultNip
                  )
                : null;
              statusVat
                ? DisplayResponse.addStatusVat(
                "Status VAT",
                statusVat,
                listResultNip
                )
                : "";
              regon
                ? DisplayResponse.addToList("Regon", regon, listResultNip)
                : null;
              pesel
                ? DisplayResponse.addToList("Pesel", pesel, listResultNip)
                : null;

                 DisplayResponse.addToList(
                    "Podmiot posiada konta wirtualne",
                    hasVirtualAccounts === true ? "Tak" : "Nie",
                    listResultNip
                  );
              accountNumbers
                ? DisplayResponse.addAccount(accountNumbers, listResultNip)
                : null;
            } else {
              DisplayResponse.addToList("Status", `<span class="tag has-background-danger has-text-white">Nie znaleziono</span>`, listResultNip);
              DisplayResponse.nullResult(listResultNip, "nip");
              resultIconNip.classList.add('has-text-danger');


            }
          }
        }).finally(() => Loader.hide());
      contentNip.classList.add("is-hidden");
      resultNip.classList.remove("is-hidden");
    });
  }
}
