const loader = document.getElementById('loader');

export class Loader {

  static show(){
    loader.classList.remove('is-hidden');
  }

  static hide(){
    loader.classList.add('is-hidden');
  }
}
