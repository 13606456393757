import {
  modalContact, contactModalBtn,
  modalEmail,
  modalEmailBtnOk,
  modalUploadFileError,
  modalUploadFileErrorBtn
} from "./const";


export class Modal {

  static email(show){
    Modal._showHide(modalEmail, show);
  }

  emailListener(){
    modalEmailBtnOk && modalEmailBtnOk.addEventListener('click',  () => {
      modalEmail.classList.remove('is-active');
      window.location.reload();
    })
  }

  static uploadFileError(show){
    Modal._showHide(modalUploadFileError, show);
  }

  uploadFileErrorListener(){
    modalUploadFileErrorBtn && modalUploadFileErrorBtn.addEventListener('click',  () => {
      modalUploadFileError.classList.remove('is-active');
      window.location.reload();
    })
  }

  static contactModal(show){
    Modal._showHide(modalContact, show)
  }


  contactModalSuccessListener(){
    contactModalBtn && contactModalBtn.addEventListener('click',  () => {
      modalContact.classList.remove('is-active');
      window.location.reload();
    })
  }





   static _showHide(elementHtml, show){
    show
      ? elementHtml.classList.add('is-active')
      : elementHtml.classList.remove('is-active')

  }
}
