import { btnSendFile, errorSendEmail, inputEmail} from "../classes/const";
import axios from "axios";
import { ApiError } from "../classes/api-errro-msg";
import { API_URL } from "../../config/config";
import { Modal } from "../classes/modal";
import {listNipAndAccount} from "../classes/csv-parse";
import { Loader } from "../classes/loader";

export class SendEmailsService {
  send() {
    btnSendFile &&
      btnSendFile.addEventListener("click", function() {
        const data = {
          email: inputEmail.value,
          list: listNipAndAccount
        };

        const showLoaderRunAxios = () => {
          Loader.show();
          axios
            .post(
              `${API_URL}/send-email.php`,
              { data },
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
            .catch(function(error) {
              Loader.hide();
              ApiError.checkError(error, errorSendEmail);
            })
            .then(response => {
              if (response.data.code === 200) {
                Modal.email(true);
              }
            })
            .finally(Loader.hide);
        };

        listNipAndAccount && listNipAndAccount.length >= 1 ? showLoaderRunAxios() : null;
      });
  }
}
