export const btnNip = document.getElementById("search-nip-btn");
export const btnAccount = document.getElementById("search-account-btn");
export const btnSendFile = document.getElementById("send-file-btn");

export const inputNip = document.getElementById("input-nip");
export const inputAccount = document.getElementById("input-number-account");
export const inputEmail = document.getElementById("input-email");

export const contentNip = document.getElementById("content-option-check-nip");
export const contentAccount = document.getElementById("content-option-check-number-account");
export const resultNip = document.getElementById("result-nip");
export const resultAccount = document.getElementById("result-account");
export const resultUpload = document.getElementById("result-upload");

export const listResultNip = document.getElementById("result-list-nip");
export const listResultAccount = document.getElementById("result-list-account");

export const errorEmail = document.getElementById("error-email");
export const errorEmailServer = document.getElementById("input-email-server");
export const errorSendEmail = document.getElementById("errorSendText");

export const btnUpload = document.getElementById("btnUpload");
export const labelInput = document.getElementById("label-input");

export const resultIconNip = document.getElementById("result-icon-nip");
export const resultIconAccount = document.getElementById("result-icon-account");
export const outputNumberAccount = document.getElementById("result");


export const modalEmail = document.getElementById("modal-email");
export const modalEmailBtnOk = document.getElementById("modal-email-btn-ok");
export const modalUploadFileError = document.getElementById("modal-error-file");
export const modalUploadFileErrorBtn = document.getElementById("modal-upload-file-error-btn");

export const tableTbodyResult = document.getElementById("table-tbody-result");
export const tableTheadResult = document.getElementById("table-th-result");

export const contactEmailInput = document.getElementById("contact-email-input");
export const contactErrorMail = document.getElementById("contact-error-email");
export const contactMsgInput = document.getElementById("contact-msg-input");
export const contactTelInput = document.getElementById("contact-tel-input");
export const contactEmailSendBtn = document.getElementById("contact-email-send-btn");
export const modalContact = document.getElementById("contact-modal");
export const contactModalBtn = document.getElementById("contact-modal-btn");

export const capchaInput = document.getElementById("g-recapcha-response");




export const weightAccount = [
  1,
  10,
  3,
  30,
  9,
  90,
  27,
  76,
  81,
  34,
  49,
  5,
  50,
  15,
  53,
  45,
  62,
  38,
  89,
  17,
  73,
  51,
  25,
  56,
  75,
  71,
  31,
  19,
  93,
  57
];
